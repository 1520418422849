



































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const model = reactive({
      name: "",
      description: "",
      main: false,
      security: [],
      limit: 0,
    });

    const state = reactive({
      buttonDisabled: true,
      loading: false,
      total: 0,
      loaded: false,
      items: [],
      error: false as boolean | number,
      controlPoints: [],
      mainAdded: {},
      isMainAdded: false,
      limit: "noLimit",
    });

    const buttonDisabled = () => {
      if (model.name) {
        state.buttonDisabled = false;
      } else {
        state.buttonDisabled = true;
      }
    };

    watch(
      () => model.name,
      () => buttonDisabled()
    );

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return `${root.$tc("layout.errors.security409")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchSingleControlPoint = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`control-point/${root.$route.params.acid}`)
        .then(({ data: { controlPointRow } }) => {
          model.name = controlPointRow.name;
          model.description = controlPointRow.description || null;
          model.main = controlPointRow.main;
          model.security = controlPointRow.security.map((s: any) => s.id);
          model.limit = controlPointRow.limit;
          state.limit = controlPointRow.limit > 0 ? "limited" : "noLimit";
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchSingleControlPoint);

    const fetchSecurity = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("user", { params: { role: "security" } })
        .then(({ data: { users, total } }) => {
          state.items = users.map((user: any, index: number) => ({
            id: index,
            name: "",
            ...user,
          }));
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchSecurity);

    const fetchControlPoints = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/control-point`)
        .then(({ data: { controlPoints, total } }) => {
          state.controlPoints = controlPoints.map(
            (user: any, index: number) => ({ id: index, name: "", ...user })
          );
          state.total = total;
          state.mainAdded = controlPoints.find((e: any) => e.main === true);
          state.isMainAdded = state.mainAdded ? true : false;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.controlPoints = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchControlPoints);

    const savePoint = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.name,
        description: model.description || null,
        main: model.main,
        security: model.security.length > 0 ? model.security : [],
        limit: state.limit === "limited" ? model.limit : 0,
      };

      state.loading = true;

      axiosInstance
        .put(`control-point/${root.$route.params.acid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("panel.event.accessControl.editSuccess"),
          });
          setTimeout(() => {
            root.$router.back();
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    return { model, state, buttonDisabled, savePoint };
  },
});
